.centerColumn {
	.centerLeft {
		.news-teaser {
			margin-bottom:60px;
			h3 .galleryIcon {
				width:30px;
				margin-left:30px;
			}
			.newsHolderImage {
				display:inline-block;
				float:left;
				margin:0 20px 20px 0;
				img {
					border-radius:30px;
				}
				.defaultImage {
					width:150px;
					height:150px;
					background-image: url(../images/newsDefault.jpg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;
					display:inline-block;
				}
			}
			.newsHolderText {
				.content {
					width: calc(100% - 170px);
    				display: inline-block;
				}
				.button-hover {
					display:inline-block;
					margin-top:30px;
					color:$LinkColor;
				}
			}
			.readMore {
				color:$LinkColor;
				&:hover {
					color:$LinkColor;
				}
			}
		}
		.contentWrapper {
			margin-bottom:60px;
		}
		

	}
	
}