.leftColumn {
	display: block;
}

body.ContentsPage {
	.centerColumn {
		.centerLeft {
			.contentObject {
				.contentTitleWrapper {
					border-bottom:2px solid $LinkColor;
					cursor:pointer;
				}
				.contentObjectContent {
					display:none;
				}
			}
		}
	}
}

.centerColumn {
	width:100%;
	max-width:1280px;
	margin:0 auto;
	display:flex;
	&:after {
		    content:'';
		    height:30vw;
		    width:50vw;
		    position: absolute;
		    left:0;
		    top:600px;
		    /*background-image:url(../images/logoIcon.png);
		    background-size:contain;
		    background-position: -60px center;
		    background-repeat: no-repeat;*/
		    opacity:0.1;
		    pointer-events:none;
		    z-index:1;
		    
		}
	ul li {
		list-style-type: disc;
		margin-left:40px;
	}
	ol li {
		list-style-type: decimal;
		margin-left:40px;
	}
	.centerRight {
		width:25%;
		display: inline-block;
		padding: 0 10px;
		/*max-width:330px;
		min-width:250px;*/
		background-color:$veryLightGrey;
		padding-bottom:460px;
		position: relative;
		&:after {
		    content:'';
		    height:360px;
		    width:100%;
		    position: absolute;
		    right:0;
		    top:40px;
		    /*background-image:url(../images/logoIcon.png);
		    background-size:contain;
		    background-position: 60px center;
		    background-repeat: no-repeat;*/
		    opacity:0.1;
		    pointer-events:none;
		}
	}
	.centerLeft {
		width: calc(75% - 100px);
		display: inline-block;
		float: left;
		padding:40px 90px 60px 10px;
		z-index:2;
		.titleCreatorWrapper {
			display: flex;
			h1 {
				width:100%;
			}
			.creator {
				display:flex;
				margin-top:20px;
				.creatorPic {
					width:80px;
					min-width:80px;
					max-width:80px;
					height:80px;
					min-height:80px;
					max-height:80px;
					background-size:cover;
					background-position: center center;
					border-radius:50%;
					display: inline-block;
					margin:0 40px 20px 0;
				}
				.creatorName {
					padding-top:20px;
				}
			}
		}
		a {
			color:$LinkColor;
		}
		h1 {
			display: block;
			color:$LinkColor;
			padding-bottom:10px;
			margin-bottom: 60px;
			font-weight:400;
			font-size:42px;
			strong {
				font-weight:800;
				color:$LinkColor;
			}
		}
		h4 {
			strong {
				font-weight:800;
				color:$LinkColor;
			}
		}
		ul {
			margin-bottom:20px;
			li {
				list-style-type: disc;
				margin-left:30px;
			}
		}

		table {
			width:100%;
			border-collapse: collapse;
			border: 1px solid $LinkColor;
			tr {
				th, td {
					padding:5px;
					border: 1px solid $LinkColor;
				}
			}
		}
		p {
			font-weight:500;
			font-size:19px;
		}

		.userform{
				.field {
					margin:30px 0;
					label {
						color:$LinkColor;
						/*font-family: $font-museo;*/
						font-weight: 100;
					}
					.text, .textarea {
						min-width:250px;
						width:calc(100% - 20px);
						padding:10px;
						/*border: 1px solid $darkBlue;*/
						margin-top:10px;
						clear:both;
					}
					
				}
				.checkboxset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.checkboxset {
							border:1px solid $lightGrey;
							list-style-type: none;
							li {
								
								margin-left:0px;
								list-style-type: none;
								padding:0 !important;
								&:before, &:after {
									display:none;
								}
								.checkbox {
									display:none;
									&:checked {
										&+ label {
											background-color:$LinkColor;
											color:#FFFFFF;
										}
									}

								}
								label {
									padding:10px !important;
									display:block;
								}
							}
						}

					}
				}
				.optionset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.optionset {
							
							list-style-type: none;
						    max-height: 40vh;
    						overflow-y: scroll;
							li {
								padding:10px;
								margin-left:0px;
								list-style-type: none;
								&:before {
									display:none;
								}
								&:after {
									content:'';
									border:1px solid $LinkColor;
									position: absolute;
									left:10px;
									top:0%;
									border-radius: 0;
									width:20px;
									height:20px;
									@include transform(translateX(0%) translateY(50%) !important);
									background-color:transparent;
									pointer-events:none;
								}
								.radio {
									display:none;
									&:checked {
										+label:before {
											content:'';
											position: absolute;
											left:0px;
											top:10PX;
											border:1px solid $LinkColor;
											width:20px;
											height:20px;
											background-color:$LinkColor;
											color:#FFFFFF;
											pointer-events:none;
										}
									}

								}
								label {
									padding:10px 10px 10px 30px;
									position: relative;
									    width: calc(100% - 50px);
									
								}
								&:hover:after {
									background-color:rgba($LinkColor, 0.5);
								}
							}
						}

					}
				}
				.file {
					.middleColumn {
						.file {
							padding:10px;
							border-radius:0;
							border:1px solid $lightGrey;
							color:$LinkColor;
						}
					}
				}
				.action {
					min-width:250px;
					width:100%;
					padding:10px;
					border: 1px solid $LinkColor;
					border-radius:0;
					background-color:transparent;
					margin-bottom:30px;
					/*color:$darkBlue;*/
					@include transition($t1);
					&:hover {
						background-color:$LinkColor;
						color:#FFFFFF;
					}
				}
			}
			form {
				fieldset {
					border:none;
					padding:0;
					.text, .textarea {
						margin-bottom:20px;
						label {
							margin-bottom:10px;
							color:$LinkColor;
						}
						.middleColumn {
							input, textarea {
								padding:10px 20px;
								width:100%;
								max-width:300px;
								font-size:18px;
								margin-top:10px;
								color:$LinkColor;
								
								border:2px solid $LinkColor;
							}
						}
					}
					.middleColumn {
						.error{
						   display: inline-block;
						   float: left;
						   clear: both;
						   padding: 10px 20px;
						   margin-bottom: 20px;
						   border: 1px solid #c70000;
						   color: #c70000;
						   background-color: #ffe7e7;
						}
					}
				}
				.Actions {
					.action {
						padding:10px 20px;
						margin-top:20px;
						width:100%;
						max-width:344px;
						border:2px solid $LinkColor;
						color:$LinkColor;
						text-align:center;
						@include transition($t1);
						background-color:transparent;
						font-size:18px;
						text-transform: uppercase;
						&:hover {
							background-color:$LinkColor;
							color:#FFFFFF;
						}
					}
				}
			}


		#SearchResults{
			padding: 0px;
			li {
				margin-bottom:30px;
				list-style: none;
				margin-left: 0px;
			}
		}

		.contentWrapper {
			margin-bottom:60px;
		}
		
		

	}
	
}