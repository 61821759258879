/*
|===============================================================
|	Homepage News Widget
|===============================================================
*/

.homepageNewsSliderWrapper {
	background-color: $LinkColor;
	padding:60px 30px 80px 30px;
	position:relative;
	@include transition($t1);
	overflow:hidden;
	h4 {
		text-align: center;
		color:#FFFFFF;
		display: block;
		margin-bottom:80px;
		font-size:50px;		
		margin-top:20px;
		font-weight:400;
		strong {
			font-weight:800;
			color:#FFFFFF;
		}
	}
	.viewMoreBtn {
		padding:10px 30px;
		background-color:red;
		border-radius:10px;
		color:$LinkColor;
		display:inline-block;
		font-weight:bold;
		@include transition($t1);
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
	&:after {
		content:'';
		position: absolute;
		left:0;
		bottom:0;
		height:50vw;
		width:40%;
		max-width:600px;
		background-image:url(../images/newsWidgetBG.svg);
		background-size:contain;
		background-position: left bottom;
		background-repeat: no-repeat;
	}
	.outer {
		max-width:1280px;
		margin:0px auto 0 auto;
		.homepageNewsSlider {
			width:100%;
			/**/
			margin:0 auto;
			display:flex;
			z-index:1;
			.slide {
				z-index:1;
				width:300px;

				background-color:#FFFFFF;
				@include clip-path(polygon(0% 0%,calc(100% - 40px) 0%, 100% 50px, 100% 100%, 40px 100%, 0% calc(100% - 50px) ));
				.inner {
					/*margin:20px;*/
					/*border:2px solid $lightGrey;*/
					padding:0 0 20px 0;
					height:calc(100% - 80px);
					position:relative;
					.newsImage {
						width:100%;
						height:290px;
						background-position: center center;
						background-size:cover;
						background-repeat: no-repeat;
					}
					.articleDate {
						color:#000000;
						padding:10px 0 20px 0;
						font-size:14px;
						border-top:1px solid $LinkColor;
						font-weight:700;
					}
					.title {
						color:$LinkColor;
						font-size:24px;
						padding:20px 20px 0 20px;
						text-transform:uppercase;

					}
					.content {
						margin:10px 20px 20px 20px;
						padding-bottom:20px;
						border-bottom:1px solid $LinkColor;
						
					}
					.readMore {
						
						padding:20px;
						display:inline-block;
						text-transform: uppercase;
						@include transition($t1);
						position:relative;
						color:$lightGrey;
						
						&:hover {
							color:$LinkColor;
						}
					}
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				
			}
		}
		
	}
	.sliderControls {
		display: block;
		text-align:center;
		margin-top:80px;
		.widgetNewsPrev, .widgetNewsNext {
			position: relative;
			
			display:inline-block;
			width:40px;
			height:40px;
			text-align:center;
			z-index:1;
			background-size: 50% 50%;
			background-position: center center;
			background-repeat: no-repeat;
			@include transition($t1);
			a {
				color:$LinkColor;
				width: 100%;
			    height: 100%;
			    display: block;
			}
			&:hover {
				background-color:$LinkColor;
				border:1px solid $LinkColor;
			}
		}
		.moreNews {
			display: inline-block;
			background-image:url(../images/moreNewsBG.svg);
			background-size:100% 100%;
			padding:12px 20px;
			color:#FFFFFF;
			text-transform: uppercase;
			font-weight: 100;
			vertical-align: top;
			margin:0 20px;
			@include transition($t1);
			&:hover {
				background-color:#FFFFFF;
				color:$LinkColor;
			}
		}
		.widgetNewsPrev {
			
			background-image: url(../images/leftBracketWhite.svg);
		}
		.widgetNewsNext {
			
			background-image: url(../images/rightBracketWhite.svg);
		}
		&.hidden {
			opacity:0;
		}
	}
	
}
