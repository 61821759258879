.bottomLinks {
	position: absolute;
	bottom:100px;
	left:50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1200px;
	display: flex;
	justify-content: center;
	a {
		margin-bottom: 20px;
		.bottomLinkItem {
			display: inline-block;
			padding: 10px 30px;
			margin: 0 10px;
			border: 5px solid #fff;
			color: $themeYellow;
			font-size: 20px;
			line-height: 20px;
			transition: 0.3s;
			&:hover {
				background: #fff;
			}
		}
	}
	
}