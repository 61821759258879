header.pageHeader {
	background-color: $mainColor;
	height: 540px;
	background-size:cover;
	background-position: center center;
	#quickLinks {
		top:auto;
		bottom:20px;
		@include transform(translateY(0) translateX(100%))
	}
	#usefulLinks {
		top:auto;
		bottom:220px;
		@include transform(translateY(0) translateX(100%))
	}
}
.headImage {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-size: cover;
	background-attachment: fixed;
	background-position: center 80px;
}
body.activeScroll {
	header {
		nav.main {
			
			.navInner {
				.headerLogo {
					/*width:150px;
					min-width:150px;
					max-width:150px;
					height:50px;*/
				}
				#desktopNavContainer {
					margin-top:30px ;
					width:calc(100% - 150px);
				}
			}
		}
	}
}

body.ContentsDisplayPage {
	header {
		position: absolute;
		
	}
}

header {
	overflow:hidden;
	position:relative;
	height:100vh;
	&.activeQuickLinks {
		#quickLinks {
			.quicklinksTab {
				&:after {
					@include transform(translateX(0%) rotate(-180deg))
				}
			}
			ul {
				@include transform(translateX(calc(-100% - 60px)))
			}
		}
		
	}
	&.activeUsefulLinks {
		#usefulLinks {
			.usefulLinksTab {
				&:after {
					@include transform(rotate(180deg))
				}
			}
			ul {
				@include transform(translateX(calc(-100% - 60px)))
			}
		}
		
	}
	nav.main {
		position:fixed;
		top:0;
		z-index:20;
		width:100%;
		background-color:#FFFFFF;
		
		
		@include transition($t1);
		
		.searchIcon {
			z-index: 4000;
			cursor: pointer;
			color: $LinkColor;
			display: inline-block;
			position: relative;
			text-align: center;
			padding: 0 40px;
			top: calc(100% + 5px);
			@include transition($t1);
			i {
				display: block;
				font-size: 24px;
			  }
			  span {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: calc(100% + 5px);
			  }
		}
		.showWeek{
			position: absolute;
			right:30px;
			top:-10px;
			background-color:$LinkColor;
			text-transform: uppercase;
			color:#FFFFFF;
			padding:10px 20px;
			font-weight: 100;
		}
		.navInner {
			width:100%;
			margin:0px auto;
			padding:20px 0;
			position:relative;
			display:flex;
			z-index:1;
			background-color:rgba(#FFFFFF,0.74);
			.headerLogo {
				position: relative;
				width:350px;
				min-width:350px;
				max-width:350px;
				height:73px;
				display:inline-block;
				background-size:contain;
				background-position: 0 top;
				background-repeat: no-repeat;
				margin-left:20px;
				@include transition($t1)
			}
			
			
			
			#desktopNavContainer {
				text-align:right;
				display:inline-block;
				width:100%;
				margin-top:30px;
				margin-right: 30px;
				@include transition($t1);
				float:right;
				#desktopNav {
					display: flex;
					justify-content: flex-end;
					.topLevel{
						position: relative;
						padding:0 20px;
						display: inline-block;
						&:last-child{
							border-right:none;
						}
						&:nth-last-child(2) {
							.subMenu {
								left:unset;
								right:0;
								@include transform(translateX(0) translateY(100%));
								.parent {
									&:before {
										left:unset;
										right:20px;
										@include transform(translateX(0))
									}
								}
							}
						}
						a {
							color: $themeYellow;
							text-transform: capitalize;
							font-weight:600;
							&:hover {
								text-decoration: underline;
							}
						}
						.subMenu {
							padding-top:30px;
							position: absolute;
							left:50%;
							bottom:0;
							/*width:300px;*/
							display:none;
							@include transform(translateX(-50%) translateY(100%));
							z-index:4;
							@include clip-path(polygon(0% 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0% 100%));
							.parent {
								width:100%;
								
								padding:20px 0;

								background-color:rgba($LinkColor, 0.84);
								@include column-count(2);
								&:before {
									content:'';
									position: absolute;
									left:50%;
									top:15px;
									@include transform(translateX(-50%));
									width:30px;
									height:15px;
									background-color:rgba($LinkColor, 0.84);
									/*background-image: url(../images/dropdownTriangle.svg);*/
									@include clip-path(polygon(50% 0%, 100% 100%, 0% 100%));
									background-size:cover;
									background-position: center top;
									
									overflow:hidden;
								}
								.stack {
									text-align:left;
									width:250px;
									a {
										color:#FFFFFF;
										text-transform: capitalize;
										padding:5px 0 5px 40px;
										display:block;
										@include transition($t1);
										position: relative;
										&:hover {
											color:$lightGrey;
											&:before {
												opacity:1;
											}
										}
										&:before {
											content:'';
											position: absolute;
											left:10px;
											top:50%;
											width:20px;
											height:20px;
											background-image:url(../images/downBracketGrey.svg);
									    	background-position:center center;
									    	background-repeat: no-repeat;
									    	background-size: contain;
									    	@include transition($t1);
									    	@include transform(rotate(-90deg) translateX(50%));
									    	opacity:0;
										}
									}
									.children {
										a {
											display:block;
										}
									}
								}
							}
						}
						&:hover {
							.subMenu{
								display:inline-block;
							}
						}
					}
				}
			}
		}
	}
	.pageHeader {
		background-color: #f4f4f4;
		width: 100%;
		height: 500px;
	}
	.flexslider {
		overflow:hidden;
		height:100vh;
		ul {
			overflow:hidden;
			height:100%;
			li {
				overflow:hidden;
				position:relative;
				height:100%;
				.slideBG {
					background-position:center center;
					background-size:cover;
					margin-top: 0 !important;
					height:100%;
				}
				.quoteBox {
					position: absolute;
					left:0;
					bottom:0;
					height:50vw;
					width:40%;
					/*background-image:url(../images/sliderBG.svg);
					background-size:contain;
					background-position: left bottom;
					background-repeat: no-repeat;*/
					quote {
						position: absolute;
						left:40px;
						bottom:8vh;
						color:#FFFFFF;
						opacity:0.63;
						font-size:8vw;
					}
				}
			}
		}
	}
}
