.ContentsDisplayWrapper {
	position: absolute;

	min-height:100vh;
	width:100vw;
	.ContentsDisplayInner {
		display: inline-block;
		margin:calc(200px + 4vh) 30% 60px 12%;
		width:58%;
		position: relative;
		z-index:2;
		.largeTitle {
			font-size:6vw;
			color:$LinkColor;
			border-bottom:1px solid $midRed;
			
			display:block;
			padding:0 5vw 0 0;
			
		}
		.contentWrapper {
			padding-top:60px;
		}
		.childrenWrapper {
			text-align:center;
			a {
				.childItem {
					display: inline-block;
					width:200px;
					height:200px;
					position: relative;
					border-radius:50%;
					margin:10px;
					@include transition($t1);
					
					.childTitle {
						position: absolute;
						left:50%;
						top:50%;
						@include transform(translateX(-50%) translateY(-50%));
						text-align:center;
						color:#FFFFFF;
						font-weight:600;
					}
				}
				&:nth-child(odd) .childItem{
					background-color:$LinkColor;
					&:hover {
						background-color:$midRed;
					}
				}
				&:nth-child(even) .childItem{
					background-color:$midRed;
					&:hover {
						background-color:$LinkColor;
					}
				}
			}
			
		}
		.contentObject {
			display:flex;
			.contentObjectLeft {
				padding-right: 40px;
			}
			.contentObjectRight {
				padding-top:70px;
				.contentObjectVideo {
					width:300px;
					height:10vw;
					min-height:200px;
				}
			}
		}
	}
	/*&:after {
	    content:'';
	    height:50vw;
	    width:50vw;
	    position: absolute;
	    right:0;
	    bottom:0px;
	    background-image:url(../images/logoIcon.png);
	    background-size:contain;
	    background-position: right center;
	    background-repeat: no-repeat;
	    opacity:0.1;
	    pointer-events:none;
	    z-index:1;
	}*/
	
}