.scrollToTop {
	position: relative;
	margin-bottom:15px;

	z-index: 4000;
	cursor: pointer;
	i {
		color: $LinkColor;
	}
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 1;
	cursor: pointer;
	i {
		text-shadow: 0 0 0.2em white;
		color: $LinkColor;
		font-size:50px;
	}
}