* {
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none;
	outline: none !important;
}

a {
	cursor: pointer;
}

html{	
	width: 100%;
	height: 100%;
}

body{
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
	margin:0;
	padding:0;
}

.contentObjectContent * {
    font-size: 1em !important;
    font-weight: normal !important;
    font-style: normal !important;
}

form .field {
    margin: 0px !important;
    padding: 0px !important;
}