#welcomeMessage {
	padding:  0;
	text-align: center;
	position: absolute;
	left:0;
	top:50%;
	width:100%;
	height:100%;
	max-width:1024px;
	z-index:1;
	@include transform(translateY(-50%));
	pointer-events:none;
	.welcomeMessageTab {
		width:190px;
		height:20px;
		display: inline-block;
		position: absolute;
		padding:20px 60px 20px 40px;
		background-color:$LinkColor;
		left:0;
		top:50%;
		@include transform(rotate(-90deg) translateX(-50%));
		@include transform-origin(0% 0%);
		@include transition($t1);
		font-weight:300;
		color:#FFFFFF;
		text-transform:uppercase;
		cursor:pointer;
		pointer-events:all;
		font-size:20px;
		&:after {
	    	content:'';
	    	position:absolute;
	    	right:0;
	    	top:0;
	    	width:60px;
	    	height:60px;
	    	background-image:url(../images/downBracketGrey.svg);
	    	background-position:center center;
	    	background-repeat: no-repeat;
	    	background-size: 25% 25%;
	    	@include transition($t1)
	    }
		&:hover {
			background-color:$midRed;
		}
	}
	.welcomeInner {
		padding:90px 20px 0px 0;
		background-color:#FFFFFF;
		display:flex;
		position: relative;
		@include transform(translateX(-100%) translateY(-50%));
		@include transition($t1);
		height:100%;
		max-height:60vh;
		/*overflow-y:scroll;*/
		top:50%;
		pointer-events:all;
		.left {
			width:200px;
			position: relative;
			.welcomeImage {
				width:200px;
				height:200px;
				background-size:cover;
				background-position:center center;
			}
			&:after {
				content:'';
				position: absolute;
				left:0;
				bottom:0;
				width:100%;
				height:100%;
				background-image:url(../images/logoIcon.svg);
				background-size:contain; 
				background-position:left calc(100% + 70px);
				background-repeat:no-repeat;
				opacity:0.2;
			}
		}
		.right {
			width:calc(100% - 200px);
			padding-left:40px;
			text-align:left;

			margin-bottom:90px;
			h4 {
				text-align:left;
				color:$midRed;
				font-weight:300;
				font-size:50px;
				/*text-transform:uppercase;*/
				margin-top:0;
				strong {
					font-weight:700;
					color:$midRed;
				}
			}
			.welcomeContent {
				overflow-y:scroll;
				    height: calc(100% - 80px);
				p {
					font-size: 20px;
				}
				&::-webkit-scrollbar{
					width:10px;
					height:9px;
				}
				&::-webkit-scrollbar-button:start:decrement,
				&::-webkit-scrollbar-button:end:increment{
					display:block;
					height:0;
					background-color:transparent;
				}
				&::-webkit-scrollbar-track-piece{
					background-color:transparent;
					@include border-radius(0px);
				}
				&::-webkit-scrollbar-thumb{
					background-color: $themeColor;
					@include border-radius(0px);
				}
				&::-webkit-scrollbar-thumb:vertical{
				}
				&::-webkit-scrollbar-thumb:horizontal{
				}
				&::-webkit-scrollbar-thumb:hover{
					background-color: $themeColor;
				}
			}
		}
		.close {

			
			text-align:center;
			display:inline-block;
			
			background-color:$LinkColor;
			margin:0px 0px 0 0;
			color:$LinkColor;
			text-transform: uppercase;
			@include transition($t1);
			cursor:pointer;
			width:80px;
			height:80px;
			position: absolute;
			right:0px;
			top:0px;
			background-image:url(../images/closeIcon.svg);
			background-position: 75% 25%;
			background-size: 25% 25%;
			background-repeat: no-repeat;
			pointer-events:all;
			@include clip-path(polygon(0% 0%, 100% 0%, 100% 100%));
			&:hover {
				background-color:$midRed;
				
			}
		}
		
	}
	&.active {
		z-index:99;
		.welcomeMessageTab {
			&:after {
				@include transform(rotate(180deg))
			}
		}
		.welcomeInner {
			@include transform(translateX(0%) translateY(-50%));
		}
	}
}