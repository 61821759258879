.missionStatement {
	padding:90px 20px;
	background-color:#FFFFFF;
	width:calc(100% - 40px);
	position: relative;
	overflow:hidden;
	h4 {
		color:$LinkColor;
		text-align:center;
		font-size:50px;
		margin:0 0 40px 0;
		font-weight:400;
		strong{
			font-weight:800;
			color:$LinkColor;
		}
	}
	.content {
		width:100%;
		max-width:600px;
		margin:0 auto;
		text-align:center;
	}
	&:before {
		content:'';
		height:90%;
		width:18vw;
		position: absolute;
		left:-40px;
		top:50%;
		background-image:url(../images/logoIcon.png);
		background-size:contain;
		background-position: left center;
		background-repeat: no-repeat;
		opacity:0.2;
		@include transform(translateY(-50%))
	}

	&:after {
		content:'';
		height:90%;
		width:18vw;
		position: absolute;
		right:-40px;
		top:50%;
		background-image:url(../images/logoIcon.png);
		background-size:contain;
		background-position: right center;
		background-repeat: no-repeat;
		opacity:0.2;
		@include transform(translateY(-50%))
	}
}