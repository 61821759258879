#modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	left:0;
	top:0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color:rgba(0,0,0,0.5);
	z-index: 9999;
	.modalWrapper {
		padding: 40px;
		width:100%;
		max-width: 500px;

		background: rgba($LinkColor, 0.6);
		padding: 40px;
		text-align:center;
		position: absolute;
	    right: 0;
	    top: 0;
	    background-image: url(../images/noticesBG.svg);
	    background-size: contain;
	    background-position: left bottom;
	    background-repeat: no-repeat;
	    h4 {
	    	color:#FFFFFF;
	    	font-size:30px;
	    	font-weight:100;
	    	strong {
	    		color:#FFFFFF;
	    		font-weight:100;
	    	}
	    }
		.modalBox {
			
			width: 500px;
			/*height: 300px;*/
			
			ul {
				li {
					height: 150px;
				}
			}
			h1 {
				color: #FFFFFF;
			}
			h1, p {
				color: #FFFFFF;
				text-align: center;
			}
		}

		.bx-pager {
			width: 100%;
			text-align: center;
			margin-top:40px;
			.bx-pager-item {
				position: relative;
				display: inline-block;
				margin: 0 5px;
				a.bx-pager-link {
					position: relative;
					display:block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					text-indent: -9999px;
					background: #FFFFFF;
					opacity: 0.5;
				}
				a.bx-pager-link.active {
					opacity: 1;
				}
			}
		}

		.close {

			padding:10px 10px;
			text-align:right;
			display:block;
			position: absolute;
			right:0;
			top:0;
			border:none;
			background-color:#FFFFFF;
			color:$LinkColor;
			text-transform: uppercase;
			@include transition($t1);
			cursor:pointer;
			width:60px;
			height:60px;
			@include clip-path(polygon(0% 0%, 100% 0%, 100% 100%));
			i {
				color:$LinkColor;
				font-size:20px;
				position: absolute;
			    top: 10px;
			    right: 10px;
			}
			&:hover {
				background-color:$midRed;
				border:none;
				
			}
		}
	}
	
}

.priorityAlert {
	position:absolute;
	-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top:50%;
    width:30%;
    min-width:240px;
    padding:40px;
    text-align: center;
    font-size:30px;
    background-color:$LinkColor;
    line-height:40px;
    color:#FFFFFF;
    z-index:1;
    .priorityAlert a {
		color:#FFFFFF;
	}

	.priorityAlertClose {
		display:block;
		width:100%;
		color:#FFFFFF;
		font-size:16px;
		line-height:40px;
		margin-top:30px;
		cursor:pointer;
	}
}

.priorityAlert a {
	color:#FFFFFF;
}

.priorityAlertClose {
	display:block;
	width:100%;
	color:#FFFFFF;
	font-size:16px;
	line-height:40px;
	margin-top:30px;
	cursor:pointer;
}
