/**
 * This typography file is included in the WYSIWYG editor
 */
.typography * {
}
.typography blockquote {
	margin-left:20px;
}
.typography hr {
	padding:5px;
}
p,
ul li,
ol li,
a {
	
	/*line-height:1.5em;
	font-size:1.1em;*/
}
p {
	margin-bottom:20px;
	display:block;
	overflow:hidden;
	img {
		/*float:none !important;*/
		vertical-align:top;
		
	}
}


/** CMS LIST STYLES **/
.typography ul,
.typography ol {
	margin-left:10px;
	margin-bottom:20px;
}
	.typography ul li,
	.typography ol li {
		margin:4px 10px;
		line-height:1.4em;
	}
		.typography ul li {
		 list-style-type:disc;
		}
		.typography ol li {
			list-style-type:decimal;
    }
/* HEADER STYLES */

h1,
h2,
h3,
h4 {
	margin-bottom:14px;
	color: $HeadingColor;
}
h1,
h4 {
	font-weight:bold;
}
h2,
h3 {
	font-weight:normal;
}
	h1 {
		font-size:2.2em;
	}
	h2 {
		font-size:2.2em;
		line-height: 1em;
	}
	h3 {
		font-size:1.6em;
	}
	h4 {
		font-size:1.4em;
	}

/* LINK STYLES */
.typography a {
	color: $LinkColor;
  	font-size:1em;
  	text-decoration:none;
}
	.typography a:hover {
		text-decoration:underline;
	}
	
	
.typography table {
	font-size:1.1em;
}
	.typography table tr td {
		padding:3px 5px;
	}

	
/* WYSIWYG EDITOR ALIGNMENT CLASSES */
.typography .left {
	text-align:left;
}
.typography .center {
	text-align:center;
}
.typography img.center {
	display: inline-block;	
}
.typography .right {
	text-align:right;
}
.typography img.right {
	float:right;
}
.typography img.left {
	float:left;
}
.typography img {
	max-width: 100%;	
}
.typography p.MsoNormal, .typography p.MsoBodyText {
	margin: 0;
}

@media all and (max-width: 760px)  {
/* Force table to not be like tables anymore */
.typography table.largeTable, .typography .largeTable thead, .typography .largeTable tbody, .typography .largeTable th, .typography .largeTable td, .typography .largeTable tr { 
	display: block; 
}

.typography table.smallTable, .typography .smallTable thead, .typography .smallTable tbody, .typography .smallTable th, .typography .smallTable td, .typography .smallTable tr { 
	display: block; 
}

.typography table.largeTable, .typography table.smallTable {
	height: auto !important;
	width: auto !important;	
	text-align: left;
}

/* Hide table headers (but not display: none;, for accessibility) */
.typography .largeTable tr.firstRow { 
	position: absolute;
	top: -9999px;
	left: -9999px;
}


.typography .largeTable tr, .typography .smallTable tr { border: 1px solid #ccc; }

.typography .largeTable tr:nth-of-type(odd), .typography .smallTable tr:nth-of-type(odd) { 
  background: #eee; 
}

.typography .largeTable td, .typography .smallTable td { 
	/* Behave  like a "row" */
	border: none;
	border-bottom: 1px solid #eee; 
	position: relative;
	padding-left: 25%; 
	width: 100%;
}

.typography .largeTable td:before { 
	/* Now like a table header */
	position: absolute;
	/* Top/left values mimic padding */
	content: attr(data-title);
	top: 6px;
	left: 6px;
	width: 20%; 
	padding-right: 10px; 
}	
}