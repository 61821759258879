// ================ Housepoints Wrapper ======================
.housepointsWrapper {
	width:calc(100% - 40px);
	text-align:center;
	background-color:$veryLightGrey;
	padding:80px 20px;
	h4 {
		color:$LinkColor;
		font-weight:100;
		text-align: center;
	    display: block;
	    margin-bottom: 80px;
	    font-size: 50px;
	    margin-top: 20px;
	    font-weight:400;
	    strong {
	    	font-weight:800;
	    	color:$LinkColor;
	    }
	}
	.housepointsInner {
		width:100%;
		max-width:1280px;
		margin:0 auto;
	   
    	text-align: center;
		.housepointsItem {
			width:200px;
			display:inline-block;
			margin:20px;
			
			.housepointsThumb {
				width:100%;
				height:140px;
				background-position:center center;
				background-size:contain;
				background-repeat:no-repeat;
			}
			.points {
				text-align:center;
				width:calc(100% - 20px);
				padding:55px 10px 0 10px;
				margin:0;
				color: #00196d;
				font-size:40px;
				/*@include clip-path(polygon(0% 0%, calc(100% - 40px) 0%, 100% 40px, 100% 100%, 0% 100%))*/
			}
			h5 {
				font-size:20px;
				margin-bottom:0;
			}
		}
	}
}

// ================ Housepoints Box ======================
.HouseBox {
	display:block;
	width:100%;
	padding:30px;
	border:2px solid $LinkColor;
	margin-bottom:60px;
	display:flex;
	.houseLogo {
		width:250px;
		height:250px;
		background-size:contain;
		background-position: center center;
		background-repeat:no-repeat;
		margin-right:30px;
		display:inline-block;
	}
	.houseInfo {
		width:calc(100% - 280px);
		h1 {
			margin-bottom:10px;
		}
		.pointsBox {
			margin-bottom:40px;
			.pointsBar {
				height:10px;
			}
		}
		.attendanceBox {
			margin-bottom:40px;
			.pointsBar {
				height:10px;
			}
		}
	}
}
.ClassPersonBox {
	display:block;
	width:100%;
	padding:30px;
	border:2px solid $LinkColor;
	margin-bottom:60px;
	display:flex;
	.classPersonImage {
		width:250px;
		height:250px;
		background-size:contain;
		background-position: center center;
		background-repeat:no-repeat;
		margin-right:30px;
		display:inline-block;
	}
	.classPersonInfo {
		width:calc(100% - 280px);
		h1 {
			margin-bottom:10px;
		}
	}
}
