.joinUsWidget {
	/*display:flex;*/
	position: relative;
	.joinUsStudents {
		width:calc(50% + 5vw);
		padding:160px 30px;
		text-align:center;
		@include clip-path(polygon(0% 0%, 100% 0%, calc(100% - 10vw) 100%, 0% 100%));
		background-size:cover;
		background-position:center center;
		position:relative;
		z-index:1;
		h4 {
			color:#FFFFFF;
			font-size:50px;
			font-weight:100;
			z-index:2;
			position:relative;
			margin-top:0;
			strong {
				font-weight:600;
				color:#FFFFFF;
				font-size:60px;
			}
		}
		.joinUs {
			display: inline-block;
			background-image:url(../images/moreNewsBG.svg);
			background-size:100% 100%;
			padding:12px 20px;
			color:#FFFFFF;
			text-transform: uppercase;
			font-weight: 100;
			vertical-align: top;
			margin:60px 20px 0 20px;
			@include transition($t1);
			z-index:2;
			position:relative;
			&:hover {
				background-color:#FFFFFF;
				color:$LinkColor;
			}
		}
		
		&:after {
			content:'';
			position: absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
			background-color:rgba($LinkColor, 0.41);
			z-index:1;
		}
	}
	.joinUsStaff {
		width:calc(50% + 1vw);
		padding:160px 30px;
		text-align:center;
		@include clip-path(polygon(10vw 0%, 100% 0%, 100% 100%, 0% 100%));
		position: absolute;
		right:0;
		top:0;
		background-size:cover;
		background-position:center center;
		z-index:2;

		h4 {
			color:#FFFFFF;
			font-size:50px;
			font-weight:100;
			z-index:2;
			position:relative;
			margin-top:0;
			strong {
				font-weight:600;
				color:#FFFFFF;
				font-size:60px;
			}
		}
		.joinUs {
			display: inline-block;
			background-image:url(../images/moreNewsBG.svg);
			background-size:100% 100%;
			padding:12px 20px;
			color:#FFFFFF;
			text-transform: uppercase;
			font-weight: 100;
			vertical-align: top;
			margin:60px 20px 0 20px;
			@include transition($t1);
			z-index:2;
			position: relative;
			&:hover {
				background-color:#FFFFFF;
				color:$LinkColor;
			}
		}
		&:after {
			content:'';
			position: absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
			background-color:rgba($midRed, 0.41);
			z-index:1;
		}
	}
}