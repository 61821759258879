.homepageFooter {
	width: 100%;
	
	background-color: #FFFFFF;
	.footerTop {
		padding:40px 80px;
		border-bottom:10px solid $lightGrey;
		display:flex;
		.footerTopLeft {

			.homepageFooterLinkIconImg {
				height:80px;
				max-width:100%;
				margin:0 20px 20px 0;
				display: inline-block;
				float:left;
			}
		}
		.footerTopRight {
			width:100%;
			text-align:right;
			font-size:30px;
			color:$LinkColor;
			padding-top:30px;
		}
	}
	.footerBottom {
		padding:40px 80px;
		display:flex;
		.footerBottomLeft {
			background-size:contain;
			background-position: left top;
			background-repeat: no-repeat;
			width:215px;
		}
		.footerBottomCenter {
			width:100%;
			padding-left:20px;
			.footerSiteTitle {
				color:$LinkColor;
				font-weight:600;
			}
		}
		.footerBottomRight {
			width:100%;
			text-align:right;
		}
	}
	
}
