.VacanciesHolder {
	.vacancy__item {
		border-bottom: 1px solid $mainColor;
		padding-bottom:40px;
		h4 {
			padding-top: 20px;
			font-weight: 300;
			
		}
	}
}