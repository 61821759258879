.homepageEventsSliderWrapper, #homepage-events {
	position: relative;
	/*float: left;*/
	background-size:cover;
	background-position:center 40%;
	padding:90px 30px 60px 30px;
	background-attachment: fixed;
	width: 100%;
	.title {
		position:relative;
		
		
		.top{
			font-size:83px;
			color:$LinkColor;
			text-align:center;
		}
	}

	
		.widgetsWrapper {
			background-color:rgba(255,255,255,0.9);
			width:100%;
			max-width:1280px;
			margin:0 auto;
			display:flex;
			position: relative;
			z-index: 2000;
				.calendar-widget-table {
					font-family:$font-stack;
					padding-bottom:0;
					thead {
						background-color: transparent;
						th {
							background-color: transparent;
							text-align:left;
							position: relative;
							padding:0 20px 20px 20px;
							.show-month {
								padding:0;
							}
							.prev, .next {
								position: absolute;
								top:0;
								/*@include transform(translateY(-50%));*/
								border:2px solid $LinkColor;
								width:40px;
								height:40px;
								background-position: center center;
								background-size:60% 60%;
								background-repeat: no-repeat;
								
								color:red;
								.inner {
									opacity:0;
								}
								&:hover {
									background-color:$LinkColor;
									border:2px solid $LinkColor;
								}
							}
							.prev {
								right:50px;
								background-image:url(../images/leftBracketBlack.svg);

							}
							.next {
								right:0;
								background-image:url(../images/rightBracketBlack.svg);
							}
						}
					} 
					tbody {
						tr {
							background-color:transparent;
							td {
								background-color:transparent;
								color:#000000;
								border:none;
								font-family: $font-stack;
								padding:15px 0;
							}
						}
					}
				}
			
			.left{
				/*float:left;*/
				h1 {
					margin-left:20px;
				}
			}
			.left, .right {
				width:50%;
				display: inline-block;
				/*float:left;*/
				padding:60px;
				/*height:600px;*/
				h1 {
					color:#000000;
					font-weight: 400;
					font-size: 70px;
				}
				
			}
			.right {
				
				.sliderControls {
					position:relative;
					height:60px;
					margin-bottom:20px;
					.allEvents {
						padding:7px 30px;
						height:40px;
						border:2px solid $LinkColor;
						display:inline-block;
						&:hover {
							background-color:$LinkColor;
						}
					}
					#eventsNext, #eventsPrev {
						position: absolute;
						top:0px;
						display:inline-block;
						width:40px;
						height:40px;
						text-align:center;
						border: 2px solid $LinkColor;
						z-index:9999;
						a {
							color:$LinkColor;
							width:100%;
							height:100%;
							display:block;
						}
						&:hover {
							background-color:$LinkColor;
							border:2px solid $LinkColor;
						}
					}
					#eventsPrev {
						right:60px;
					}
					#eventsNext {
						right:0px;
					}
				}
			}
		}
	#sliderUpcoming {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		section {
			padding-bottom:10px;
			.sliderContent {
				background-color:$LinkColor;
				padding:15px;
				font-family: $font-stack;
				.title, .date {
					width: 100%;
				}
				.title {

				}
				.date {
					display: inline-block;
					font-weight: 700;
					font-size: 14px;
				}
				&:hover{
					background-color: #000000;
					color:$LinkColor;
					.date {
						color:$LinkColor;
					}
				}
			}
		}
		
		#eventsInner {
			z-index:1;
			section {
				margin-bottom:60px;
				.eventsContent {
					text-align: justify;
				}
			}
		}
	}
}