#breadcrumbs {
	background-color: $LinkColor;
	padding: 20px;

	.inner {
		width:calc(100% - 20px);
		max-width: 1280px;
		margin:0 auto;
		padding:0 10px;
	}
	p {
		color: $lightGrey;
		margin: 0;
		span, a {
			color: #FFFFFF;
		}
	}
}
