.homepageTwitter {
	position:relative;
	/*float: left;*/
	width:100%;
	display: block;
	background-size:cover;
	background-position:center center;
	padding:80px 0;
	text-align:center;
	h4 {
		text-align: center;
		color:#FFFFFF;
		display: block;
		/*margin-bottom:80px;*/
		font-size:50px;		
		margin-top:20px;
		font-weight:400;
		z-index:1;
		position: relative;
		strong {
			font-weight:800;
			color:#FFFFFF;
		}
	}
	.twitterOverlay {
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		background-color:rgba($LinkColor,0.6);
		&:after {
			content:'';
			position: absolute;
			right:0;
			top:0;
			height:50vw;
			width:40%;
			max-width:500px;
			background-image:url(../images/socialWidgetBG.svg);
			background-size:contain;
			background-position: left top;
			background-repeat: no-repeat;
		}
	}
	.twitterLogo {
		display: block;
		width:80px;
		height:80px;
		margin:0 auto 40px auto;
		background-image:url(../images/twitterLogo.svg);
		background-size:contain;
		background-position:center center;
		background-repeat: no-repeat;
	}
	
	.twitterPrev, .twitterNext {
		width:20px;
		height:20px;
		position:absolute;
		top:50%;
		@include transform(translateY(-50%));
		color:$mainColor;
		cursor:pointer;
	}
	.twitterPrev {
		content:'⇦';
		left:-40px;
	}
	.twitterNext {
		content:'⇨';
		right:-40px;
	}
	section {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		padding:20px;
	}
	.homepageTwitterThumb {
		width:120px;
	}
	.visitTwitter {
		display: inline-block;
		background-image:url(../images/moreNewsBG.svg);
		background-size:100% 100%;
		padding:12px 20px;
		color:#FFFFFF;
		text-transform: uppercase;
		font-weight: 100;
		vertical-align: top;
		margin:60px auto 0 auto;
		@include transition($t1);
		z-index:2;
		position:relative;
		&:hover {
			background-color:#FFFFFF;
			color:$LinkColor;
		}
	}
	
}

#tweets {
	position: relative;
	padding:40px 40px 40px 20px;
	text-align:left;

	width:calc(100% - 100px);
	margin:0 auto;
	max-width:768px;
	background-color:rgba(#FFFFFF, 0.9);
	@include clip-path(polygon(0% 0%, calc(100% - 60px) 0%, 100% 60px, 100% 100%, 60px 100%, 0% calc(100% - 60px)));
	.title__block {
		h4 {
			color:#FFFFFF;
			margin:0;
			font-size:35px;
			font-weight:300;
		}
	}
	
		
			
			.tweetItem {
				width: 100%;
				color: $textDark;
				padding-bottom: 20px;
				.tweetWrapper {
					display: flex;
					border-bottom: 2px solid $lightGrey;
					padding-bottom:40px;
					.twitterThumb {
						width:80px;
					}
					.tweetTextWrapper {
						padding-left:20px;
						display:flex;
						width:calc(100% - 100px);

						.tweetText {
							font-weight: 300;
							color: $textDark;
							line-height: 30px;
							font-size: 18px;
							/*height: 300px;*/
							width:100%;
							padding-right:20px;
							.retweetStatus {
								color:$lightGrey;
								font-size:14px;
								font-weight:600;
							}
							.tweetName {
								font-size:15px;
								strong {
									font-weight:700;
								}
							}
							.frame {
								
								display:flex;
							}
						}
						.tweetImage {
							width:100%;
							max-width:200px;
							
						}
						
					}
					
				}
				
				&:nth-child(2) {
					.tweetWrapper {
						
						border-bottom:none;
						
					}
					
				}
				
			}

			
			.tweetHandle {
				a {
					font-weight: 300;
					color: red;
				}
			}
			.tweetMeta {
				width: 151px;
				padding: 10px 0;
				text-align: left;
				float: left;
				.meta1, .metaRetweet, .metaLike {
					display: inline-block;
					margin-right: 15px;
					svg {
						path {
							fill: $lightGrey;
							@include transition($t1)
						}
					}
					svg:hover {
						path {
							fill:$midRed;
						}
					}
				}
			}
			.tweetData {
				float: left;
				width: calc(100% - 151px);
				text-align: right;
				/*padding-top: 15px;*/
				text-align: right;
				p {
					color: #FFFFFF;
					display: inline-block;
					margin:10px 0 10px 10px;
					display: inline-block;
					height: auto;
					font-size: 15px;
					span {
						color: #FFFFFF;
					}	
				}
			}
		
	
	.controlNav {
		position: relative;
		.prev, .next {
			position: relative;
			border: 1px solid $mainColor;
			display: inline-block;
			padding: 10px;
			margin: 0;
			width:30px;
			height: 30px;
			line-height: 0px;
			cursor: pointer;
			a {
				width:100%;
				height:100%;
				display:block;
			}
		}
		.next {
			margin-left: 20px;
		}
		.prev:hover, .next:hover {
			background: red;
			svg {
				polygon {
					fill: white;
				}
			}
		}
	}
	.bgs {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		.yellow {
			position: relative;
			background-color: white;
			width: 50%;
			height: 100%;
		}
	}
}
