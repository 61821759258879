/*** Mobile Screens ***/
#sidebar{
  width: calc(100% - 40px);
  margin:40px 40px 40px 0;
  /*float: right;*/
  position: relative;
  
  .Level-1{
    display: none;
    ul{
      display: none;
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    /*float: left;*/
    clear: both;
    /*width: 80%;*/
    margin-left:30px;
    a{
      padding: 5px 30px 5px 10px;
      display: block;
      text-decoration: none;
      color: $textDark;

    }
  }
  ul{
    padding:0;
    margin:0;
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
          margin-left: 20px !important;
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
            margin-left: 30px !important;
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      margin:0;
      padding:0;
      list-style-type: none;
      a{
        display: block;
        padding: 5px 30px 5px 40px;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);
        &:before {
          content:'';
          position: absolute;
          left:10px;
          top:50%;
          width:20px;
          height:20px;
          background-image:url(../images/downBracketGrey.svg);
          background-position:center center;
          background-repeat: no-repeat;
          background-size: contain;
          @include transition($t1);
          @include transform(rotate(-90deg) translateX(50%));
          opacity:0;
          pointer-events:none;
        }
        &.current {
          &:before {
            opacity:1;
          }
          span {
            color:$LinkColor;
          }
        }
        span{
          color: $textDark;
          @include transition($t1);
          font-weight:500;
        }
        &:hover span{
          color: $themeColor !important;
        }
        &:hover{
          &:before {
            opacity:1;
          }
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
