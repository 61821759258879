#usefulLinks {
	display: flex;
	justify-content: space-between;
	padding: 100px;
	background-color: $mainColor;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	.thumb {
		position: relative;
		flex-basis: 400px;
		height: 400px;
		margin: 0 20px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: 0.3s;
		&:hover {
			box-shadow: 3px 3px 5px 1px rgba(0,0,0,0.4);
		}
	}
	a {
		display: block;
		height: 100%;
		width: 100%;
	}
	.titleText {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: right;
		padding: 20px;
		background: $lightGrey;
		color: #fff;
		box-sizing: border-box;
		font-weight: bold;
		font-size: 22px;
		line-height: 24px;
		min-height: 96px;
		span {
			color: #fff;
			display: block;
			font-weight: normal;
		}
	}
}
/*#usefulLinks {
	display: block;
	position: absolute;
	right:0;
	top:calc(50% - 20px);
	@include transform(translateY(-150%) translateX(100%));
	z-index: 1;
	height:180px;
	background-color:#FFFFFF;
	.usefulLinksTab {
		position: absolute;
		left:0;
		width:140px;
		height:18px;
		@include transform(translateX(-100%) rotate(90deg));
		@include transform-origin(100% 0%);
		bottom:-60px;
		padding: 20px 40px 20px 20px;
    	background-color: $lightGrey;
    	font-weight: 300;
	    color: #FFFFFF;
	    text-transform: uppercase;
	    cursor: pointer;
	    @include transition($t1);
	    font-size:18px;
	    z-index:2;
	    &:after {
	    	content:'';
	    	position:absolute;
	    	right:0;
	    	top:0;
	    	width:60px;
	    	height:60px;
	    	background-image:url(../images/downBracketRed.svg);
	    	background-position:center center;
	    	background-repeat: no-repeat;
	    	background-size: 25% 25%;
	    	@include transition($t1)
	    }
	    &:hover {
	    	background-color:$LinkColor;
	    }
	}
	ul{
		position: relative;
		
		margin:0;
		padding:0;
		height:100%;
		@include transition($t1);
		z-index:1;
	}
	li {
		height: 100%;
		width:200px;
		align-items: center;
		background-position: center center;
		background-size: cover;
		position: relative;
		color: white;
		font-weight: 100;
		display: inline-block;
		opacity:1;
		@include transition($t1);
		background-color:rgba(#FFFFFF,0.8);
		border-right:2px solid $lightGrey;
		float:left;
		a {
			display:block;
			width:100%;
			height:100%;
			@include transition($t1);
			font-size:20px;
			position: relative;
			.thumb {
				width:100%;
				height:150px;
				background-size:60% auto;
				background-position:center center;
				background-repeat: no-repeat;
			}
			.titleText {
				position: relative;
			    display: block;
			    text-align:center;
			    color:$LinkColor;
			   
			    @include transition($t1);
			    text-align:center;
			}
			
			&:hover {
				.outlineBox {
					svg {
						polyline {
							@include transition($t2);
							stroke-dashoffset: 0;
						}
					}
					&:after{
						opacity:1;
						@include transition($t1)
					
					}
				}
			}
			
		}
		&:hover {
			background-color:#FFFFFF;
		}
		&:last-child {
			border-right:none;
		}
	}
}*/