

@media all and (max-width:1480px) {
	#MenuIcon {
		display:inline-block !important;
	}
	header {
		nav.main {
			.navInner {
				
				#desktopNavContainer {
					//display: none !important;
					.topLevel {
						display: none !important;
					}
					#MenuIcon {
						border-left: none !important;
					}
				}
			}
		}
	}

	#usefulLinks,
	#quickLinks {
		padding: 50px 20px;
	}
}

@media all and (max-width:1024px) {
	header {
		.flexslider {
			.slides {
				li {
					.quoteBox {
						width:20% !important;
					}
				}
			}
		}
		#welcomeMessage, #usefulLinks, #quickLinks {
			display: none !important;
		}
	}
	.centerLeft {
		width:calc(100% - 20px) !important;
		padding:0 10px !important;
	}
	.centerRight {
		display: none !important;
	}
	#usefulLinks,
	#quickLinks {
		flex-wrap: wrap;
		padding: 50px 20px 0;
		justify-content: center;
		.thumb {
			margin-bottom: 50px;
		}
	}
	.joinUsWidget {
		.joinUsStaff {
			width: calc(50% - 7vw) !important;
		}
	}
	.ContentsDisplayWrapper {
		.ContentsDisplayInner {
			margin:calc(200px + 4vh) 40px 60px 40px !important;
			width:calc(100% - 80px) !important;
		}
	}
	footer {
		.footerBottom {
			display:block !important;
			.footerBottomLeft {
				height:100px;
				margin:0 auto 30px auto;
				background-position:center top !important;
			}
			.footerBottomCenter {
				padding-left:0 !important;
				display: inline-block;
				float:left;
				width:50% !important;
			}
			.footerBottomRight {
				width:50% !important;
				display:inline-block !important;
			}
		}
	}
}

@media all and (max-width: 1000px)  {
	.clearLarge {
		width: 100% !important;
		clear: both;
	}
}
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}

@media all and (max-width: 768px)  {

	header {
		.flexslider {
			.slides {
				li {
					.quoteBox {
						display:none !important;
					}
				}
			}
		}
		.bottomLinks {
			flex-wrap: wrap;
		}
	}
	.joinUsWidget {
		.joinUsStudents, .joinUsStaff {
			width:calc(100% - 60px) !important;
			@include clip-path(unset !important);
			position: relative !important;
		}
	}
	.leftColumn {
		display:block !important;
		#sidebar {
			float:left;
			width:100% !important;
			max-width:100% !important;
		}
	}
	.centerLeft {
		width:calc(100% - 20px) !important;
		padding:0 10px !important;
	}
	.centerRight {
		display: none !important;
	}
	#MobileNavigation {
		width:100%;
		right:-100%;
	}
	#SearchForm_SearchForm fieldset {
		#SearchForm_SearchForm_Search {
			font-size:30px;
			width:240px !important;
		}
		#SearchForm_SearchForm_action_results {
			width: 30px;
    		height: 30px;
    		margin-left:10px;
    		margin-right:10px;
		}
	} 
	.ContentsDisplayWrapper {
		.ContentsDisplayInner {
			.contentObject {
				display:block !important;
				.contentObjectLeft {
					padding-right:0 !important;
				}
				.contentObjectRight {
					padding-top:40px !important;
					.contentObjectVideo {
						width:100% !important;
						height:50vw !important;
					}
				}
			}
		}
	}
	footer {
		.footerBottom {
			.footerBottomCenter {
				width:100% !important;
				float:none !important;
				text-align:center;

			}
			.footerBottomRight {
				width:100% !important;
				text-align:center !important;
				p {
					text-align:center !important;
				}
			}
		}
	}
}

@media all and (max-width:640px) {
	.centerColumn {
		.centerLeft {
			.titleCreatorWrapper {
				display:block !important;
				h1 {
					margin-bottom:20px !important;
				}
			}
		}
	}
	#tweets {
		padding:40px 20px !important;
		.tweetItem .tweetWrapper {
			.twitterThumb {
				display:none !important;
			}
			.tweetTextWrapper {
				padding-left:0 !important;
				width:100% !important;
			}
		}
	}
	footer {
		.footerTop {
			display:block !important;
			.footerTopLeft {
				text-align:center;
				display:block;
				text-align:center;
				.homepageFooterLinkIconImg {
					float:none !important;
				}
			}
			.footerTopRight {
				text-align:center !important;
			}
		}
	}
}

@media all and (max-width:670px) {

	header {
		nav.main .navInner .headerLogo {
			min-width: 220px;
			background-position: center
		}
		
		.scrollToBottom {
			display:none !important;
		}
	}
}

@media all and (max-width: 450px)  {
	body.activeScroll header nav.main .navInner #desktopNavContainer,
	header nav.main .navInner #desktopNavContainer {
		margin-top: 0;
	} 
	header nav.main .navInner .headerLogo {
		min-width: 190px;
		margin-right: 10px;
	}
	#MenuIcon,
	header nav.main .searchIcon {
		padding: 0 20px;
	}
	#usefulLinks .thumb, 
	#quickLinks .thumb {
		height: 300px;
	}
	#SearchForm_SearchForm fieldset {
		#SearchForm_SearchForm_Search {
			font-size:30px;
		}
		#SearchForm_SearchForm_action_results {
			    width: 30px;
    			height: 30px;
    			margin-top:15px;
		}
	}
	.tacSmall {
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
	header {
		nav.main {
			.navInner {
				.headerLogo {
					max-width:170px !important;
					height:50px !important;
				}
			}
		}
	}
	footer {
		.footerBottom {
			padding:40px 20px !important;
		}
	}
}



@media all and (max-height:1024px) {
	header.homepageHeader {
		#quickLinks {
			top:calc(50% + 140px) !important;
		}
		#usefulLinks {
			top:calc(50% + 120px) !important;
		}
	}
}


@media all and (min-width:1025px) and (max-height:960px) {
	header.homepageHeader {
		#welcomeMessage, #usefulLinks, #quickLinks {
			display: none !important;
		}
	}
}











