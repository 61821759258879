/*** Mobile Navigation ***/
#MenuIcon {
    z-index: 4000;
    cursor: pointer;
    color: $LinkColor;
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 0 40px;
    border-left: 1px solid $lightGrey;
    border-right: 1px solid $lightGrey;
    @include transition($t1);
    i {
      display: block;
      font-size: 24px;
    }
    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + 5px);
    }
    p {
      font-size: 0.8em;
      color: #fff;
      text-transform: uppercase;
      font-weight: lighter;
      position: absolute;
      left: 50%;
      @include transform(translate(-50%));
      bottom: 12px;
    }
}

.mobileNavBg {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.4);
	z-index: 6005;
	visibility: hidden;
	@include transition(all 1s);
	opacity: 0;
}

#MenuBg {
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
  background-color: $themeColor;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
#MobileNavigation{
  background-color: $themeColor;
  @include transition(all 1s);
  position: fixed;
  z-index: 5999;
  top: 0px;
  right: -60%;
  height: 100%;
  overflow: hidden;
  width: 60%;
  min-width: 300px;
  opacity: 0;
  .closeMenu {
  	position: absolute;
  	top: 30px;
  	right: 30px;
  	width: 40px;
  	height: auto;
  }
  .toggleNav {
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    font-weight: lighter;
    top: 5px;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 27px;
    border: none;
    outline: none;
  }
  .TopLevel {
    padding: 10px;
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    overflow-y: scroll;
    @include transform($center);
    width:100%;
    max-width: 300px;
    text-align: left;
    max-height:60vh;
    overflow-x:hidden;
    &::-webkit-scrollbar{
      width:10px;
      height:9px;
    }
    &::-webkit-scrollbar-button:start:decrement,
    &::-webkit-scrollbar-button:end:increment{
      display:block;
      height:0;
      background-color:transparent;
    }
    &::-webkit-scrollbar-track-piece{
      background-color:#eee;
      @include border-radius(0px);
    }
    &::-webkit-scrollbar-thumb{
      background-color: $midRed;
      @include border-radius(0px);
    }
    &::-webkit-scrollbar-thumb:vertical{
    }
    &::-webkit-scrollbar-thumb:horizontal{
    }
    &::-webkit-scrollbar-thumb:hover{
      background-color: $themeColor;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }
  ul {
    list-style-type: none;
    padding:0;
    margin:0;
    ul {
      display: none;
    }
    li {
      position: relative;
      a {
        color: #fff;
        text-align: left;
        font-size: 1em;
        font-family: $font-stack;
        width: calc(100% - 54px);
        display: block;
        font-weight: lighter;
        padding: 10px 34px 10px 20px;
        letter-spacing: 2px;
        text-decoration: none !important;
        @include transition($t1);
        &:hover {
          background: rgba(255,255,255,0.1);
        }
      }
    }
  }
}
.activeMobileMenu {
  overflow: hidden;
  #MobileNavigation {
    display: block;
    right: 0px;
    opacity: 1  !important;
    margin-top: 0px !important ;
    height: 100%;
  }
	.mobileNavBg {
		visibility: visible;
		opacity: 1;
	}
  #MenuBg {
    width: 200%;
    height: 300%;
  }
   #MenuIcon {
      i {
        display: block;
      }
  }
}
